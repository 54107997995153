<template>
  <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
    <div class="small-box bg-green pointer" v-on:click="$router.push('/task/cars')">
      <div class="inner" style="min-height: 103px;">
        <h5>Создать задачу</h5>
      </div>
      <div class="icon">
        <i class="fas fa-file-medical"></i>
      </div>
      <a class="small-box-footer" title="Создать" style="padding-top: 6px; padding-bottom: 6px;">
        <i class="fas fa-plus-circle"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'create-task-box',

  props: {

  }
}
</script>